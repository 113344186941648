<template lang="pug">
div.container
  div(v-if="!events")
    loading-spinner(message="Loading events...")
  div(v-if=("events"))
    div.row.justify-content-center
      h1 Select an event to check people in
    br/
    br/
    div.row
      h3 Current or Upcoming Events
    div.row
      table.table.table-bordered.table-hover.table-striped
        thead.thead-dark
          tr
            th Organizer
            th Event Name
            th Sponsor
            th Venue
            th Date
        tbody
          tr.table-row-link(v-for="event in currentEvents" :key="event._id" @click="$router.push('/events/checkin/' + event._id)")
            td {{event.organizer}}
            td {{event.name}}
            td {{event.sponsor}}
            td {{event.location}}
            td {{event.date | moment("MMMM YYYY")}}
    br/
    div.row
      h3 Past Events
    div.row
      table.table.table-bordered.table-hover.table-striped
        thead.thead-dark
          tr
            th Organizer
            th Event Name
            th Sponsor
            th Venue
            th Date
        tbody
          tr.table-row-link(v-for="event in pastEvents" @click="$router.push('/events/checkin/' + event._id)")
            td {{event.organizer}}
            td {{event.name}}
            td {{event.sponsor}}
            td {{event.location}}
            td {{event.date | moment("MMMM YYYY")}}
</template>

<script>
export default {
  data: function () {
    return {
      events: null
    }
  },
  computed: {
    currentEvents: function () {
      let result = []
      let currentDate = new Date().getTime() - 86400000 // 86400000 = 24h
      for (let event of this.events) {
        if (event.date > currentDate) {
          result.push(event)
        }
      }
      return result
    },
    pastEvents: function () {
      let result = []
      let currentDate = new Date().getTime() - 86400000 // 86400000 = 24h
      for (let event of this.events) {
        if (event.date < currentDate) {
          result.push(event)
        }
      }
      // result.sort(function(a,b){return a.date - b.date})
      return result
    }
  },
  mounted: function () {
    this.axios.get('/api/events/headlines')
      .then(res => {
        for (let e of res.data) { e.date = new Date(e.date) }

        res.data.sort(function (a, b) { return b.date - a.date })
        this.events = res.data
      })
      .catch(err => (this.$notify({
        group: 'error',
        type: 'error',
        title: 'Fetching events failed',
        text: err.response.data,
        duration: -1
      })))
  }
}
</script>
